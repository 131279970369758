<!-- splash screen -->
<div #splashScreen id="splash-screen" class="flex flex-col justify-center items-center w-full h-full">
  <img class="max-w-60 max-h-24 min-w-60 min-h-24 w-60 h-24" src="./assets/media/client-logos/WordMark-CentreAligned/PNG/VividTealDark.png" alt="Dashboard Insights logo" />
  <svg class="splash-spinner" viewBox="0 0 50 50">
    <circle
      class="path"
      cx="25"
      cy="25"
      r="20"
      fill="none"
      stroke-width="5"
    ></circle>
  </svg>
</div>
