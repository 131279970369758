import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule, APP_INITIALIZER, ErrorHandler } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { HttpClientInMemoryWebApiModule } from 'angular-in-memory-web-api';
import { ClipboardModule } from 'ngx-clipboard';
import { TranslateModule } from '@ngx-translate/core';
import { InlineSVGModule } from 'ng-inline-svg';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AmplifyService } from 'aws-amplify-angular';
import { environment } from 'src/environments/environment';
// Highlight JS
import { HighlightModule, HIGHLIGHT_OPTIONS } from 'ngx-highlightjs';
import { SplashScreenModule } from './_metronic/partials/layout/splash-screen/splash-screen.module';
// #fake-start#
import { FakeAPIService } from './_fake/fake-api.service';
import { DataTableModule } from './shared/dataTable/data-table.module';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { NgxPermissionsModule } from 'ngx-permissions';
import { SessionService } from './shared/service/session.service';
import { UserService } from './features/user/user.service';
import { AuthState } from 'aws-amplify-angular/dist/src/providers';
import { AuthInterceptor } from './shared/service/http.intercetor';
import { ConfirmationDialogComponent } from './shared/modals/confirmation/confirmation.component';
import { FirstTimeLoginComponent } from './shared/modals/first-time-login/first-time-login.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ChatbotModule } from './features/chatbot/chatbot/chatbot.module';
import { AuthGuardService } from './modules/auth/_services/auth/authguard.service';
import { LoadingComponent } from './shared/component/loading/loading.component';
import { LoadingService } from './shared/service/loading.service';
import { ToastrModule } from 'ngx-toastr';
import * as Sentry from "@sentry/angular";
import { Router } from '@angular/router';
import { NgxStripeModule } from 'ngx-stripe';
import { GlobalErrorHandler } from './shared/handlers/error-handler';
// #fake-end#

function appInitializer(
  amplifyService: AmplifyService,
  sessionService: SessionService,
  userService: UserService) {
  return () => {
    return new Promise<void>(async (resolve) => {

      amplifyService.authStateChange$
        .subscribe(async (state: AuthState) => {
          console.log(state)
          if (!sessionService.amplifyInitialState || (state && state.state === 'signedOut')) {

            if (!state.state) {
              sessionService.amplifyInitialState = 'signedOut';
            }

            sessionService.amplifyInitialState = state.state
          }

          // If signed in
          if (
            state.state != 'confirmSignUp' &&
            state.state != 'requireNewPassword' &&
            sessionService.amplifyInitialState !== 'signedOut' &&
            state.user && state.user.signInUserSession.idToken
          ) {
            console.log(state.user.signInUserSession.idToken.jwtToken);
            sessionService.saveIdToken(state.user.signInUserSession.idToken.jwtToken);
            let currSession = await userService.session(state.user.attributes.email).toPromise()
            sessionService.setSessionOnInit(currSession)
          }

          return resolve()

        }).add(resolve)

    });
  };
}


@NgModule({
  declarations: [AppComponent, ConfirmationDialogComponent, FirstTimeLoginComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    SplashScreenModule,
    TranslateModule.forRoot(),
    HttpClientModule,
    HighlightModule,
    ChatbotModule,
    ClipboardModule,
    FormsModule,
    ReactiveFormsModule,
    NgxPermissionsModule.forRoot(),
    // #fake-start#
    environment.isMockEnabled
      ? HttpClientInMemoryWebApiModule.forRoot(FakeAPIService, {
        passThruUnknownUrl: true,
        dataEncapsulation: false,
      })
      : [],
    // #fake-end#
    AppRoutingModule,
    InlineSVGModule.forRoot(),
    NgbModule,
    DataTableModule,
    PerfectScrollbarModule,
    ToastrModule.forRoot({
      timeOut: 5000,
      progressBar: true,
      autoDismiss: true,
      maxOpened: 3,
      preventDuplicates: true
    }),
    NgxStripeModule.forRoot(environment.stripePK)
  ],
  providers: [
    AmplifyService,
    AuthGuardService,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializer,
      multi: true,
      deps: [AmplifyService, SessionService, UserService],
    },
    // {
    //   provide: APP_INITIALIZER,
    //   useFactory: (session: SessionService, ps: NgxPermissionsService ) => function() { return session.createTestSession() },
    //   deps: [SessionService, NgxPermissionsService, UserService],
    //   multi: true
    // },
    {
      provide: HIGHLIGHT_OPTIONS,
      useValue: {
        coreLibraryLoader: () => import('highlight.js/lib/core'),
        languages: {
          xml: () => import('highlight.js/lib/languages/xml'),
          typescript: () => import('highlight.js/lib/languages/typescript'),
          scss: () => import('highlight.js/lib/languages/scss'),
          json: () => import('highlight.js/lib/languages/json')
        },
      },
    },
    LoadingService,
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    }, {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler
    }
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
