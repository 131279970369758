import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Auth } from "aws-amplify";

@Component({
  selector: 'app-signedupwithxero',
  templateUrl: './signedupwithxero.component.html',
  styleUrls: ['./signedupwithxero.component.scss'],
})
export class SignedupwithxeroComponent implements OnInit, OnDestroy {
  constructor(
    private route: ActivatedRoute,
    private router: Router
  ) {
  }

  ngOnInit(): void {
  }

  ngOnDestroy() {
    
  }
}
