
export const permissions = {
  viewAdminUser: 'viewAdminUser',
  viewClient: 'viewClient',
  addClient: 'addClient',
  editClient: 'editClient',
  viewReport: 'viewReport',
  addReport: 'addReport',
  editReport: 'editReport',
  viewPlatform: 'viewPlatform',
  addPlatform: 'addPlatform',
  editPlatform: 'editPlatform',
  viewUser: 'viewUser',
  addUser: 'addUser',
  editUser: 'editUser',
  viewUserReports: 'viewUserReports',
  viewUserGroup: 'viewUserGroup',
  addUserGroup: 'addUserGroup',
  editUserGroup: 'editUserGroup',
  navBackClient: 'navBackClient',
  navBackUser: 'navBackUser',
  viewPlatformLog:'viewPlatformLog',
  requestSupport: 'requestSupport',
  viewReportId: 'viewReportId',
  viewPowerBiId: 'viewPowerBiId'
}

