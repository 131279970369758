import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './modules/auth/_services/auth.guard';
import { SignupwithxeroComponent } from './modules/auth/signupwithxero/signupwithxero.component';
import { SignedupwithxeroComponent } from './modules/auth/signedupwithxero/signedupwithxero.component';
import { ReRouteXeroComponent } from './modules/auth/re-route-xero/re-route-xero.component';

export const routes: Routes = [
  {
    path: 'signupwithxero',
    component: ReRouteXeroComponent,
    data: { returnUrl: window.location.pathname }
  },
  {
    path: 'signupwithxeroform',
    component: SignupwithxeroComponent,
    
  },
  {
    path: 'signedupwithxero',
    component: SignedupwithxeroComponent,
    data: { returnUrl: window.location.pathname }
  },
  {
    path: 'login',
    loadChildren: () =>
      import('./modules/bento-auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'home',
    loadChildren: () =>
      // import('./modules/home/home.module').then((m) => m.HomeModule),
      import('./modules/bento-auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'usersignup-confirmation',
    loadChildren: () =>
      import('./modules/signupconfirmation/signupconfirmation.module').then((m) => m.SignupconfirmationModule),
  },
  {
    path: 'usersignin-confirmation',
    loadChildren: () =>
      import('./modules/login-link-confirmation/login-link-confirmation.module').then((m) => m.LoginLinkConfirmationModule),
  },
  {
    path: 'reset-password',
    loadChildren: () =>
      import('./modules/reset-password/reset-password.module').then((m) => m.ResetPasswordModule),
  },
  {
    path: 'error',
    loadChildren: () =>
      import('./modules/errors/errors.module').then((m) => m.ErrorsModule),
  },
  {
    path: 'chat',
    loadChildren: () =>
      import('./features/chatbot/chatbot/chatbot.module').then((m) => m.ChatbotModule),
  },
  {
    path: 'wfm-client-signup',
    loadChildren: () =>
      import('./modules/bento-auth/components/client-signup/client-signup.module').then((m) => m.ClientSignupModule),
  },
  {
    path: 'signup',
    loadChildren: () =>
      import('./modules/bento-auth/components/new-client-signup/new-client-signup.module').then((m) => m.NewClientSignupModule),
  },
  {
    path: 'xero-client-signup',
    loadChildren: () =>
      import('./modules/bento-auth/components/new-client-signup/new-client-signup.module').then((m) => m.NewClientSignupModule),
  },
  {
    path: 'karbon-client-signup',
    loadChildren: () =>
      import('./modules/bento-auth/components/new-client-signup/new-client-signup.module').then((m) => m.NewClientSignupModule),
  },
  {
    path: 'fyi-client-signup',
    loadChildren: () =>
      import('./modules/bento-auth/components/new-client-signup/new-client-signup.module').then((m) => m.NewClientSignupModule),
  },
  {
    path: 'myob',
    loadChildren: () =>
      import('./modules/myob-platform/myob-routing.module').then((m) => m.MyobRoutingModule)
  },
  {
    path: 'client-signup',
    redirectTo: '/wfm-client-signup',
    pathMatch: 'full'
  },
  {
    path: 'client/:clientId/projectstatus',
    loadChildren: () =>
      import('./features/project-status/project-status.module').then((m) => m.ProjectStatusModule),
  },
  {
    path: '',
    canActivate: [AuthGuard], // AUTH GUARD IS GREAT
    loadChildren: () =>   
      import('./features/features.module').then((m) => m.FeaturesModule),
  },
  { path: '**', redirectTo: 'home' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
