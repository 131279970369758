<h3>
  {{translate.instant('TEXT.' + translateTitle)}}
</h3>
<div class="m-stack m-stack--flex-tablet-and-mobile m-stack--ver m-stack--desktop">
  <div class="m-stack__item m-stack__item--right m-stack__item--middle m-stack__item--first">
    <ul class="m-nav m-nav--inline m--pull-right">
      <li class="m-nav__item">
        <a class="m-nav__link">
          <span class="m-nav__link-text">
            <button mat-button color="primary"
              routerLink="/facilities/new/">{{translate.instant('TEXT.ADD_NEW_FACILITY')}}</button>
          </span>
        </a>
      </li>
      <li class="m-nav__item m-nav__item">
        <a href="#" class="m-nav__link" data-toggle="m-tooltip" title="Support Center" data-placement="left">
          <i class="m-nav__link-icon flaticon-info m--icon-font-size-lg3"></i>
        </a>
      </li>
    </ul>
  </div>
</div>