<div class="flex flex-col justify-center items-center">
  <div class="p-2 heading">
    Welcome
  </div>
  <div class="p-6 text-center">
    Welcome to Dashboard Insights, Use the menu on the left to navigate through your company dashboards
  </div>
  <div class="p-6 text-center" style="margin-top: -7%;" *ngIf="platfomType=='6'">
    To get the most from your WFM Dashboards, access your Getting Started Guide <a href="https://dashboardinsights.com/blog/wfm-dashboards-getting-started-guide/" rel="noopener noreferrer" target="_blank"><b>here</b></a>
  </div>
  <div>
    <button (click)="closeModal()" type="button" class="di-button-primary">Close</button>
  </div>
</div>