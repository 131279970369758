<div class="heading py-4 px-2">{{title ? title : 'CONFIRM'}}</div>
<div class="py-4 message-container">
  <h6 *ngFor="let message of messages" class="mb-5">{{ message }}</h6>
</div>
<div class="flex justify-end items-center">
  <div class="pr-2">
    <button (click)="closeModal(true)" type="button" class="di-button-primary">Ok</button>
  </div>
  <div>
    <button *ngIf="cancelButton" (click)="closeModal(false)" type="button" class="di-button-light">Cancel</button>
  </div>
</div>