<div class="flex-row-fluid" style="background:#3ec4bb;color:#fff;font-size: 18px;line-height: 40px;">

    <div class="d-flex justify-content-between mb-3">
      <div class="ml-20 header-mergins">Welcome to Dashboard Insights</div>
      <div>
        <i style="color:#fff" class="fa fa-phone mr-20 header-mergins" > &nbsp;	1300 481 178</i>
  
      </div>
    </div>
   
  </div>

  <div class="home-container" id="kt_login_wrapper">
    <!-- Header -->
  
    <nav class="px-20 py-3">
      <div class="row">
       <div class="col-12 col-lg-6">
         <div>
           <img src="../../../../../assets/media/logos/horizontal logo.png" alt="Dashboard Insights Logo"
             class="d-none d-sm-block">
           <img src="../../../../../assets/media/client-logos/logo-large.png" alt="Dashboard Insights Logo"
             class="d-block d-sm-none" style="margin-left: -23%;">
         </div>
       </div>
      </div>
     </nav>
    <iframe
src="https://www.chatbase.co/chatbot-iframe/upJXBbO-mANp2yQkwpc3s"
width="100%"
style="height:78%;"
frameborder="0"
></iframe>
</div>


