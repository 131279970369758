
<div class="container">
  <div class="row">
    <div class="col-sm">
      <h1 style="width: 100%; margin-top: 13%;">Dashboard Insights welcomes you to a 30 day free trial.</h1>
      <img src="../../../../../assets/media/xerosignup/layer2.png" alt="" style="width: 130%; margin-top: 0%;"/>
    </div>
    <div class="col-sm"></div>
    <div class="col-sm">
      <div style="margin-left:25%">  <img src="../../../../../assets/media/client-logos/logo-large.png" class="login-logo" alt="" style="width: 43%;margin-bottom: 13%;margin-top: 11%;" /></div>
      <div class="login-form login-signin background" [formGroup]="confirmSignupFormWithXero">
        <div class="form-group">
      
          <label class="font-size-h6 font-weight-bolder text-dark" style="margin-top: 0.5em;">First name</label>
      
          <input class="form-control form-control-solid h-auto py-7 px-6 rounded-lg" type="email" name="email"
            formControlName="firstName" autocomplete="off"
             />
      
        </div>
      
        <div class="form-group">
      
          <label class="font-size-h6 font-weight-bolder text-dark" style="margin-top: 0.5em;">Surname</label>
      
          <input class="form-control form-control-solid h-auto py-7 px-6 rounded-lg" type="text" name="code"
            formControlName="sureName" autocomplete="off"
             />
      
        </div>
        <div class="form-group">
      
                <label class="font-size-h6 font-weight-bolder text-dark" style="margin-top: 0.5em;">E-mail Address</label>
            
                <input class="form-control form-control-solid h-auto py-7 px-6 rounded-lg" type="text" name="code"
                  formControlName="emailAddress" autocomplete="off"
                  />
            
              </div>
    
              <div class="form-group">
      
                <label class="font-size-h6 font-weight-bolder text-dark" style="margin-top: 0.5em;">Mobile Number</label>
            
                <input class="form-control form-control-solid h-auto py-7 px-6 rounded-lg" type="text" name="code"
                  formControlName="phone" autocomplete="off" 
                />
            
              </div>
        <div class="pb-lg-0 pb-5 button-container">
      
          <button type="submit" id="kt_login_signin_submit" style="width: 50%;"
            class="btn btn-primary font-weight-bolder font-size-h6 px-8 py-4 my-3 mr-3" (click)="submit()">
            Sign up
          </button>
          <!-- <button class="btn btn-light font-weight-bolder font-size-h6 px-8 py-4 my-3 mr-3" (click)="back()">
            Back
          </button> -->
      
        </div>
      </div> 
    </div>
  </div>
</div>


   
      


