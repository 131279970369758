import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import * as Sentry from "@sentry/angular";

@Injectable({
  providedIn: 'root'
})

export class GlobalErrorHandler implements ErrorHandler {
  constructor(private injector: Injector){ }

  handleError(error: Error | HttpErrorResponse) {
    Sentry.captureException(error);
    const router = this.injector.get(Router);
    if(error instanceof HttpErrorResponse){
      console.error(error);
    }
    else{
      router.navigate(['/error/error-5']);
    }
  }
}
