import { Injectable, EventEmitter } from "@angular/core";
import { BehaviorSubject } from "rxjs/internal/BehaviorSubject";

@Injectable({
    providedIn: "root",
  })
  export class InterConnectServiceService {
  private userDetail = new BehaviorSubject("");
  currentUser = this.userDetail.asObservable();
  private reportsDetail = new BehaviorSubject<any>("");
  getReports = this.reportsDetail.asObservable();
  private userCredentialsStore = new BehaviorSubject<any>("");
  getUserCredentials = this.userCredentialsStore.asObservable();
  private callRefreshFunc = new BehaviorSubject<any>("");
  getcallRefreshFunc = this.callRefreshFunc.asObservable();
  private callUserListFunc = new BehaviorSubject<any>("");
  getUserListRefreshFunc = this.callUserListFunc.asObservable();
  private userCode = new BehaviorSubject<any>("");
  getCode = this.userCode.asObservable();
  private scheduleDashboardMessage = new BehaviorSubject<any>("");
  getScheduleMessage = this.scheduleDashboardMessage.asObservable();
  constructor() {
  }
  changeUserInfo(user: string) {
    this.userDetail.next(user);
  }
  storeUserReports(reports: any) {
    this.reportsDetail.next(reports);
  }
  resetUserPassword(password: any) {
    this.userCode.next(password);
  }
  userCredentials(object: any) {
    this.userCredentialsStore.next(object);
  }
  callRefresh(object: any) {
    this.callRefreshFunc.next(object);
  }
  userList(object: any) {
    this.callUserListFunc.next(object);
  }
  postScheduleMessage(object: any) {
    this.scheduleDashboardMessage.next(object);
  }
  }