<div>
  <div class="relative group flex w-full items-center">
    <mat-form-field class="w-full mt-6">
      <mat-select ngClass="{'isHeader': mat-select-platform }" #matSelect [multiple]="multiple" [ngModel]="initialValue" (ngModelChange)="onValueChange($event)"
        [placeholder]="placeholder">
        <mat-select-trigger *ngIf="multiple && initialValue.length > 0">
            {{initialValue.length > 0 && getValue(initialValue[0])}}
            <span *ngIf="initialValue.length > 1 && initialValue[0] !== -1"
                class="example-additional-selection">
                (+{{initialValue.length - 1}} {{initialValue.length === 2 ? 'other' : 'others'}})
            </span>
        </mat-select-trigger>
        <mat-option *ngIf="clearable">Clear</mat-option>
        <mat-option *ngFor="let option of getOptions()" [value]="option.value">
          {{option.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div *ngIf="canSort" mat-sort-header></div>
</div>