import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import * as Sentry from "@sentry/angular";

import Amplify from '@aws-amplify/core';
import amplify from './aws-exports.js';
Amplify.configure(amplify);

if (environment.name == 'production') {
  enableProdMode();
}

if (["prod", "production"].includes(environment.name)) {
  window.console.log = () => { }
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));

Sentry.init({
  dsn: environment.sentryDsn,
  integrations: [
    Sentry.browserTracingIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [environment.sentryTracePropagationTargets],
  // Session Replay
  replaysSessionSampleRate: environment.name === "prod" ? 0.1 : 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
});