<div style="display: flex; max-width: inherit;">
  <mat-form-field>
    <mat-label>{{placeholder}}</mat-label>
    
    <input matInput *ngIf="!range" [matDatepicker]="picker" (click)="picker.open()" #inputfield (dateChange)="onValueChange()" [(ngModel)]="value" />
    <mat-datepicker *ngIf="range" #picker></mat-datepicker>
    
    <mat-date-range-input [rangePicker]="picker" *ngIf="range" >
      <input matStartDate [(ngModel)]="start" placeholder="Start Date" (click)="picker.open()" />
      <input matEndDate [(ngModel)]="end" placeholder="End Date" (click)="picker.open()" (dateChange)="onValueChange()" />
    </mat-date-range-input>
    <mat-date-range-picker #picker></mat-date-range-picker>
    
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
  </mat-form-field>
  <div *ngIf="canSort" style="flex: 1; margin: auto" mat-sort-header></div>
</div>
