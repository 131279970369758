export interface ClientModel {
    id: string,
    name: string,
    address: string,
    phone: string,
    email: string,
    powerBIWorkspace: string,
    contact: string,
    permissions: string,
}

export enum Modes {
  summary,
  edit
}

export enum ClientTabs {
  Report = 0,
  Platform = 1,
  User = 2,
  UserGroup = 3,
  Staff = 4
}

export enum ClientTypes {
  Standard = 1,
  Restricted = 2,
  Advisor=3
}