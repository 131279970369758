import { Component, OnInit } from '@angular/core';
import { Auth } from "aws-amplify";

@Component({
  selector: 'b-re-route-xero',
  templateUrl: './re-route-xero.component.html',
  styleUrls: ['./re-route-xero.component.scss']
})
export class ReRouteXeroComponent implements OnInit {

  constructor() { 
    Auth.federatedSignIn({ customProvider: 'Xero' });
  }

  ngOnInit(): void {
  }

}
