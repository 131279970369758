import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DataTableComponent } from './data-table/data-table.component';
import { DataTableHeaderComponent } from './data-table-header/data-table-header.component';
import { InputHeaderCellComponent } from './input-header-cell/input-header-cell.component';
import { SelectHeaderCellComponent } from './select-header-cell/select-header-cell.component';
import { FormsModule } from '@angular/forms';
import { SubtitileCellComponent } from './subtitile-cell/subtitile-cell.component';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { DateHeaderCellComponent } from './date-header-cell/date-header-cell.component';
import { MatDatepickerModule } from '@angular/material/datepicker';


@NgModule({
  imports: [
    CommonModule,
    MatIconModule,
    MatTableModule,
    MatInputModule,
    MatDatepickerModule,
    MatSelectModule,
    FormsModule,
    MatSortModule
  ],
  declarations: [
    InputHeaderCellComponent,
    SelectHeaderCellComponent,
    DataTableComponent,
    DataTableHeaderComponent,
    SubtitileCellComponent,
    DateHeaderCellComponent
  ],
  exports: [
    InputHeaderCellComponent,
    SelectHeaderCellComponent,
    DateHeaderCellComponent,
    DataTableComponent,
    DataTableHeaderComponent,
    SubtitileCellComponent
  ]
})
export class DataTableModule { }
