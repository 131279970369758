<div class="d-flex flex-column flex-root h-100" id="kt_login_wrapper">
  <!--begin::Login-->
  <div class="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-column-fluid bg-white" id="kt_login">
    <!--begin::Aside-->
    <div class="login-aside d-flex flex-column flex-row-auto"
      style="background: url('../../../../../assets/media/bg/bg-login.png') no-repeat;background-size:cover">
      <!--begin::Aside Top-->
      <div class="d-none d-md-flex flex-column-auto flex-column pt-lg-40 pt-15">
      </div>
      <!--end::Aside Top-->

    </div>
    <!--begin::Aside-->

    <!--begin::Content-->
    <div
      class="login-content flex-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden p-7 mx-auto">

      <a href="#" class="mb-10 pt-lg-40 pt-15">
        <img src="../../../../../assets/media/client-logos/logo-large.png" class="max-h-70px" alt="" style="margin-right:42%" />
      </a>


      <!--begin::Content body-->
      <div class="d-flex flex-column-fluid flex-center pb-lg-40 pb-15">


        <div class="login-form login-signin">


          <div class="pb-13 pt-lg-0 pt-5">
            <h3 class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg">
              Thank you for signing up to Dashboard Insights.
            </h3>
          </div>

          <p>
            A member of our Dashboard Insights team will be in touch shortly.
          </p>

        </div>
        <!--end::Content body-->

        <!--begin::Content footer-->
        <!-- <div class="d-flex justify-content-lg-start justify-content-center align-items-end py-7 py-lg-0">
                <a href="#" class="text-primary font-weight-bolder font-size-h5">Terms</a>
                <a href="#" class="text-primary ml-10 font-weight-bolder font-size-h5">Plans</a>
                <a href="#" class="text-primary ml-10 font-weight-bolder font-size-h5">Contact Us</a>
            </div> -->
        <!--end::Content footer-->
      </div>
      <!--end::Content-->
    </div>
    <!--end::Login-->
  </div>