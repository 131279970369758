import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EnvironmentService } from './environment.service';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  url: string
  wssUrl: string

  constructor(public http: HttpClient, public env: EnvironmentService) {
    if (env.localApi) this.url = 'http://localhost:5001'
    else this.url = `https://api-${env.name === 'devtest' ? 'dev' : env.name}.dashboardinsights.com/Prod`
  }

  post(method, parameters, authorise = false): Observable<any> {
    let headers = new HttpHeaders();
    // if (authorise) headers = headers.set('Authorization', session.getIdToken().getJwtToken());

    return this.http.post(`${this.url}/api/${method}`, parameters, { headers })

    // Auth.currentSession().then((session) => {
    //   let headers = new HttpHeaders();
    //   if (authorise)
    //     headers = headers.set('Authorization', session.getIdToken().getJwtToken());
    //   this.http.post(this.url + '/api/' + method, parameters, { headers: headers }).toPromise().then((data) => {
    //     resolve(data)
    //   }).catch(async (error) => {
    //     console.log(error)
    //     resolve()
    //   })
    // })

  }

  get(method, params, authorise = false): Observable<any> {

    let headers = new HttpHeaders();
    // if (authorise) headers = headers.set('Authorization', session.getIdToken().getJwtToken());

    return this.http.get(`${this.url}/api/${method}`, { headers, params })

    // Auth.currentSession().then((session) => {
    //   let headers = new HttpHeaders();
    //   if (authorise) { headers = headers.set('Authorization', session.getIdToken().getJwtToken()); }
    //   this.http.get(this.url + '/api/' + method, { headers: headers, params: parameters }).toPromise().then((data) => {
    //     resolve(data)
    //   }).catch(async (error) => {
    //     console.log(error)
    //     resolve()
    //   })
    // })

  }
  get_File(method, params, authorise = false): Observable<any> {

    let headers = new HttpHeaders();

    return this.http.get(`${this.url}/api/${method}`, { headers, params,responseType:'text' })
  }

  delete(method, params, authorise = false): Observable<any>{
    let headers = new HttpHeaders();

    return this.http.delete(`${this.url}/api/${method}`, { headers, params })
  }
}
