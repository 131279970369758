import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'b-date-header-cell',
  templateUrl: './date-header-cell.component.html',
  styleUrls: ['./date-header-cell.component.scss']
})
export class DateHeaderCellComponent implements OnInit {

  @Input() canSort = false;
  @Input() placeholder: string = '';
  @Input() matHeaderCellDef: string;
  @Input() range: boolean = false;
  @Output() valueChange: EventEmitter<any> = new EventEmitter();
  @Input() initialValue;
  value;
  start;
  end;

  constructor() { }

  ngOnInit(): void {
    if (this.initialValue) {
      if (this.range) {
        this.start = this.initialValue.start;
        this.end = this.initialValue.end;
      }
      else {
        this.value = this.initialValue;
      }
      this.onValueChange();
    }
  }

  onValueChange = () => {
    if (!this.range) {
      this.valueChange.emit(this.value);
    }
    else if (this.range && this.start && this.end) {
      this.valueChange.emit({
        start: moment(this.start).startOf('d').toDate(),
        end: moment(this.end).endOf('d').toDate()
      });
    }
  }

}
