import { Component, OnInit, OnDestroy, ChangeDetectorRef, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators,FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { SessionService } from 'src/app/shared/service/session.service';
import { AuthService } from '../../bento-auth/auth.service';
import { AuthFormService } from '../../bento-auth/forms/auth-form.service';
import { AuthPageStates } from '../../bento-auth/models/auth.model';


@Component({
  selector: 'app-signupwithxero',
  templateUrl: './signupwithxero.component.html',
  styleUrls: ['./signupwithxero.component.scss'],
  providers:[FormBuilder]
})
export class SignupwithxeroComponent implements OnInit, OnDestroy {
 confirmSignupFormWithXero: FormGroup
 firstName:string="";
 xeroUserID:string="";
 surName:string="";
 // @Output() stateChange: EventEmitter<AuthPageStates> = new EventEmitter()
  constructor(
    private session: SessionService,
    private router: Router,
    private authService: AuthService,
    private builder: FormBuilder
  ) { 
   
  }

  ngOnInit(): void {
    this.confirmSignupFormWithXero = this.builder.group({
        firstName: ['',
        Validators.compose([
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(100),
        ]),
        ],
        sureName: ['',
        Validators.compose([
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(100),
        ]),
        ],
        emailAddress: ['',
        Validators.compose([
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(100),
        ]),
        ],
        phone: ['',
        Validators.compose([
          Validators.minLength(3),
          Validators.maxLength(100),
        ]),
      ]
      })
    
    this.authService.getCurrentAuthenticatedUser()
        .then(this.signinAfterCognitoConfirmedXeroSignUP)
        .catch( e => console.log('No authenticated user'));
  }


  signinAfterCognitoConfirmedXeroSignUP = async (user) => {
    let splitName:any;
    if(user.attributes.name)
    {
      splitName=user.attributes.name.split(" ", 2)
     }
     this.xeroUserID=user.username;
    console.log('Logged In with Amplify by Xero direct api!!', user)
    this.authService.cognitoUser = user

    this.confirmSignupFormWithXero.get('emailAddress').patchValue(user.attributes.email)
        this.confirmSignupFormWithXero.get('firstName').patchValue(splitName[0])
        this.confirmSignupFormWithXero.get('sureName').patchValue(splitName[1])
        this.confirmSignupFormWithXero.get('phone').patchValue('')
    return;
  }

  submit = async (): Promise<void> => {
  let formValues=this.confirmSignupFormWithXero.value;
  if(!formValues.phone)
  {
    formValues.phone='Not recorded';
  }
  this.authService.signupEmail(formValues.firstName,formValues.sureName,formValues.emailAddress,formValues.phone,this.xeroUserID);
  //this.auth.signupEmail('81545488-5c35-46b4-a33d-3646a81812f6');
  this.router.navigate(['/signedupwithxero'])
  }

  private validate = (form: FormGroup): boolean => {
    let isValid = true;

    if (form.invalid)
      isValid = false

    if (form.get('password').value !== form.get('confirmPassword').value)
      isValid = false

    return isValid;
  }
  

  
  ngOnDestroy() {
    
  }
}
