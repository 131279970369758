import { Component, OnInit, Input, Output, EventEmitter, ViewChild, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { MatSelect } from '@angular/material/select';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'b-select-header-cell',
  templateUrl: './select-header-cell.component.html',
  styleUrls: ['./select-header-cell.component.scss']
})
export class SelectHeaderCellComponent implements OnInit, AfterViewInit {
  @ViewChild(MatSelect) matSelect: MatSelect;
  @Output() onBlur = new EventEmitter();

  @Input() canSort = false;
  @Input() isHeader = false;
  @Input() translatePlaceholder: string;
  @Input() options = [];
  @Input() translateOptions: string;
  @Output() valueChange: EventEmitter<any> = new EventEmitter();
  @Input() initialValue: any;
  @Input() setToFirst = false;
  @Input() multiple = false;
  @Input() value: any;
  @Input() placeholder;
  @Input() clearable: boolean = false;
  constructor(public translate: TranslateService, private cdRef: ChangeDetectorRef) { }

  ngOnInit() {
    if (this.multiple && !this.initialValue) {
      this.initialValue = [];
    } else {
      if (this.setToFirst) {
        var options = this.getOptions();
        if (options && options.length > 0) {
          this.initialValue = options[0].value;
        }
      }
    }
    if (this.translatePlaceholder) {
      this.placeholder = this.translate.instant('TEXT.' + this.translatePlaceholder)
    }
  }

  ngAfterViewInit() {
    this.matSelect._closedStream.subscribe(value => {
      this.onBlur.emit(this.initialValue)
    })
  }

  onValueChange(nextValue) {
    const previousValue = this.initialValue;

    if (nextValue === undefined && this.clearable) {
      this.valueChange.emit( this.multiple ? [] : null );
      return;
    }
    
    if(Array.isArray(nextValue)) {
      if (nextValue.find(v => v === -1) && !previousValue.find(v => v === -1)) {
        this.initialValue = this.getOptions().map(option => (option.value));
      } else if (previousValue.find(v => v === -1) && !nextValue.find(v => v === -1)) {
        this.initialValue = [];
      } else if (previousValue.find(v => v === -1) && nextValue.find(v => v === -1)) {
        this.initialValue = [...nextValue];
        this.initialValue.splice(0, 1);

      } else {
        this.initialValue = nextValue;
      }
      this.valueChange.emit([nextValue]);
    }
    else {      
      this.valueChange.emit(nextValue);
    }

    this.cdRef.detectChanges();
  }

  getValue(val) {
    let foundOption = this.getOptions().find((o: any) => o.value === val);

    if(foundOption) {
      return foundOption.name;
    } else {
      return "";
    }
  }

  getOptions() {
    if (this.translateOptions) {
      return this.translate.instant('OPTIONS.' + this.translateOptions);
    }
    if (this.options) {
      return this.options
    }
    return [];
  }

}
