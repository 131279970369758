import { Injectable } from '@angular/core'
import { version } from '../../../../package.json'
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EnvironmentService {
  current = () => { return this[this.name] }
  name = 'test'
  version = version
  errorHandling = false
  stripePK=environment.stripePK;
  clientId = environment.clientId;
  clientIdMYOB = environment.clientIdMyOB;
  isScheduledReport = environment.isScheduledReport;
  price1 = environment.price1;
  price11 = environment.price11;
  price51 = environment.price51;
  price100 = environment.price100;
  priceBundle = environment.priceBundle;
  price1AU=environment.price1AU;
  price6AU=environment.price6AU;
  priceBundleAU=environment.priceBundleAU;
  localApi = true
  signin = {
    enabled: true,
    user: 'bento@bento.solutions',
    password: 'J1m&Gr3g4Lyf'
  }

  constructor() {
    // DO NOT TOUCH - change environment in environment.js
    if (environment.name != 'dev') {
      this.name = environment.name
      this.localApi = false
      this.signin.enabled = false
    }
  }
}
