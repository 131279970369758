import { EventEmitter, Injectable, Output } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, from, Subscription } from 'rxjs';
import { UserService } from 'src/app/features/user/user.service';
import { SessionService } from 'src/app/shared/service/session.service';
import { Auth } from "aws-amplify";
import { ApiService } from 'src/app/shared/service/api.service';
import { InterConnectServiceService } from '../../shared/service/interconnected.services';
import { AuthPageStates } from './models/auth.model';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  cognitoUser: any = null
  loginError: any = null
  authEmail$: BehaviorSubject<string> = new BehaviorSubject('')
  signupHasError$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  @Output() stateChange: EventEmitter<AuthPageStates> = new EventEmitter()
  hasError: boolean;
  returnUrl:any;
  private unsubscribe: Subscription[] = [];
  constructor(
    private userService: UserService,
    private session: SessionService,
    private router: Router,
    private api: ApiService,
    private connectedServices:InterConnectServiceService,
  ) { }

  public changeStorage = () => Auth.configure({ storage: window.sessionStorage });
  public xeroSignin = () => Auth.federatedSignIn({ customProvider: 'Xero' });

  public getCurrentAuthenticatedUser = () => Auth.currentAuthenticatedUser();

  public login = (email, password) => from(Auth.signIn(email, password));

  public loginToDI = (email: string): Promise<boolean> => {
    return new Promise(resolve => {
      this.userService.session(email)
        .subscribe(async (session) => {
          console.log('UserSession', session)
          this.session.setSessionDefaultClient(session)
          this.session.setSession(session)
          let route = this.session.refreshHomeNav()
          // If a valid login
          if (session?.currentUsername || session?.currentUserId == '00000000-0000-0000-0000-000000000000') {
            console.log('User next route', route)
            if (!route) {
              return resolve(false)
            }
            else {
              if(route.includes("/users/")){
                await this.session.loadPermissions();
              }
              this.router.navigate([route])
              return resolve(true)
            }
          }
          else {
            resolve(false);
          }

        }, (error) => {
          return resolve(false)
        }, () => {
          return resolve(true)
        })
    })
  }

  public completeNewPassword = (user: any, password: string) => from(Auth.completeNewPassword(user, password, null))

  //public forgotPasswordRequest = (username: string) => from(Auth.forgotPassword(username))
  public forgotPasswordRequest = (username: string) => this.forgotPasswordDI(username)

  public forgotPasswordSubmit = (username: string, code: string, password: string) => from(Auth.forgotPasswordSubmit(username, code, password))

  public signup = (username: string, password: string) => from(this.signupLogic(username, password))

  public signupInvites = (username: string, password: string,token:string, fromBRWFMSignup = false) => from(this.signupLogicInvites(username, password,token, fromBRWFMSignup))
  public setPasswordPoint = (username: string, password: string,token:string) => from(this.setPassword(username, password,token))
  public confirmSignup = async (username: string, code: string): Promise<any> => Auth.confirmSignUp(username, code);

  public logout = () => from(this.logoutLogic())

  public resetPassword = (username: string) => Auth.forgotPassword(username)

  // Private Methods
  private signupLogic = (username: string, password: string): Promise<any> => {
    console.log(username);
    console.log(password);
    console.log('xerosignup');
    return new Promise(async resolve => {
      try {
        const cogResponse = await Auth.signUp({
          username: username.toLowerCase(),
          password: password,
          attributes: {
            email: username.toLowerCase()
          }
        });
        console.log('Cog response:', cogResponse);

        return this.api.post(`user/signup`, { email: username })
          .subscribe(response => {
            return resolve(response)
          })

      } catch (err) {
        console.log('cog user exists.')

        return resolve(err)
      };

    })
  }

  signInWithoutCognito=async (email:any)=>{
        let loginSuccess = await this.loginToDI(email);
        if (!loginSuccess) {
          this.loginError = {
            message: 'This account is not currently active. Please contact your company admin to set up your account.'
          }
        }
      }
  // Private Methods
  private signupLogicInvites = (username: string, password: string,token:any, fromBRWFMSignup): Promise<any> => {
    console.log(username);
    console.log(password);
    console.log('xerosignup');
    return new Promise(async resolve => {
          return this.api.post(`open/ui/user/markActive`, { email:username,password: password,token:token})
            .subscribe(
              token => {
                debugger;
                console.log(token);
                if (token && token.idToken) {
                  this.session.saveIdToken(token.idToken);
                  this.session.saveRefreshToken(token.refreshToken);
                  this.isTokenExpired(3600);

                  if(!fromBRWFMSignup){
                    this.signInWithoutCognito(username);
                  }
                }
                return resolve(token)
              },
              error => { this.signupHasError$.next(true); return error; }
            )
    })
  }
  private isTokenExpired(seconds) {
    let minutes = Math.floor(seconds/ 60);
    var currentTimeDate = new Date();
    currentTimeDate.setMinutes(currentTimeDate.getMinutes() + minutes-10);
    this.session.saveTokenExpiry(currentTimeDate);
    console.log('expirein');
    console.log(currentTimeDate);
  }
  private setPassword = (username: string, password: string,token:any): Promise<any> => {
    console.log(username);
    console.log(password);
    console.log('xerosignup');
    return new Promise(async resolve => {
          return this.api.post(`open/ui/user/setPassword`, { email:username,password: password,token:token})
            .subscribe(token => {
              debugger;
              console.log(token);
              if (token && token.idToken) {
                this.session.saveIdToken(token.idToken);
                this.session.saveRefreshToken(token.refreshToken);
                this.isTokenExpired(3600);
                this.signInWithoutCognito(username);
              }
              return resolve(token)
            })
    })
  }

  private forgotPasswordDI = (username: string) => {
    console.log(username);
    console.log('reset password');
    return this.api.post(`open/ui/user/verifyemail`, { email:username});
  }

  signin = (email,password): void => { // old method
    this.login(email, password)
      .subscribe(async (user: any) => {
        await this.signinAfterCognitoConfirmed(user);
        var userCredentials ={email:email, password:password};
        this.connectedServices.userCredentials(JSON.stringify(userCredentials));
      }, (error: any) => {

        if (error.code === "PasswordResetRequiredException") {
          this.forgotPassword();
        } else if (error.code === "UserNotConfirmedException") {
          this.confirmSignupInvites(email);
        } else {
          this.loginError = error
        }

      })

  }

  forgotPassword = (): void => {
    debugger;
    this.stateChange.emit(AuthPageStates.FORGOT_PASSWORD)
  }

  confirmSignupInvites = (email): void => {
    debugger;
    this.connectedServices.resetUserPassword(email);
    this.stateChange.emit(AuthPageStates.CONFIRM_SIGNUP)
  }

 
  signinAfterCognitoConfirmed = async (user) => { // old method
    debugger;
    console.log('Logged In with Amplify!!', user)
    this.cognitoUser = user
    localStorage.removeItem('requiresSignout');
    // USE :: user.attributes.email
    let loginSuccess = await this.loginToDI(user.attributes.email);
    if (!loginSuccess) {
      this.loginError = {
        message: 'This account is not currently active. Please contact your company admin to set up your account.'
      }
    }

    return;
  }

  public signupEmail = (firstName: any, surName: any, emailAddress: any, phone: any, xeroUserID: any): Promise<any> => {
    return new Promise(async resolve => {
      try {
        return this.api.post(`user/email/signupemail/${firstName}/${surName}/${emailAddress}/${phone}/${xeroUserID}`, {})
          .subscribe(response => {
            return resolve(response)
          })
      } catch (err) {
        console.log('cog user exists.')

        return resolve(err)
      };

    })
  }

  private logoutLogic = (): Promise<void> => {

    return new Promise(async (resolve) => {
      localStorage.removeItem("session");
      await Auth.signOut() // Needs to be last, if logged in w Xero will reload the app & redirect to /login
      return resolve()
    })
  }

}
