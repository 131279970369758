import {
  Component,
  OnInit,
  ChangeDetectorRef,
  OnDestroy,
  AfterViewInit,
} from "@angular/core";
import { PageEvent } from "@angular/material/paginator";
import { Sort } from "@angular/material/sort";
import * as moment from "moment";
import { BehaviorSubject } from "rxjs";

@Component({
  selector: "m-data-table",
  template: "",
  styleUrls: ["./data-table.component.scss"],
})
export class DataTableComponent implements OnInit, AfterViewInit {
  allData: any[] = [];
  mobileAllData: any[] = [];
  dataSource: BehaviorSubject<any[]> = new BehaviorSubject([]);
  displayDataSource: BehaviorSubject<any[]> = new BehaviorSubject([]);
  infScrollDataSource: BehaviorSubject<any[]> = new BehaviorSubject([]);
  displayinfScrollDataSource: BehaviorSubject<any[]> = new BehaviorSubject([]);

  pageEvent: PageEvent;
  pageIndex = 0;
  length: number = 0;
  pageSize = 10;

  loading = false;

  usePaging = true;

  filters: any = {};
  sortKey = "";
  sortDirection = navigator.userAgent.match(/firefox|fxios/i) ? "desc" : "asc";

  constructor() { }

  ngAfterViewInit(): void {
    this.dataSource.subscribe((data) => {
      this.allData = data;
      this.sortData(null);
      this.filterData();
    });
    
    this.infScrollDataSource.subscribe((data) => {
      this.mobileAllData = data;
      this.sortData(null, true);
      this.filterData(true);
    });
  }

  ngOnInit() { }

  public onPage(event?: PageEvent) {
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;
    this.sliceDataSource();
    return event;
  }

  // table sort

  sortData(sort: Sort, isMobile = false) {
    if (sort) {
      this.sortKey = sort.active;
      this.sortDirection = sort.direction;
    }
    if(isMobile){
      this.mobileAllData = this.allData.sort((a, b) => {
        return this.compare(
          a[this.sortKey],
          b[this.sortKey],
          this.sortDirection === "asc"
        );
      });
      this.filterData(isMobile);
    }
    else{
      this.allData = this.allData.sort((a, b) => {
        return this.compare(
          a[this.sortKey],
          b[this.sortKey],
          this.sortDirection === "asc"
        );
      });
      this.filterData(isMobile);
    }
  }

  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  onFilter(value, key, isMobile = false) {
    if (value === null) {
      delete this.filters[key];
    }
    else if (moment.isMoment(value) || moment.isDate(value)) {
      this.filters[key] = value;
    }
    else if (typeof value === "string" || value instanceof String || typeof value === "number" || value instanceof Number) {
      if (value === "") {
        delete this.filters[key];
      } else {
        this.filters[key] = value;
      }
    } else {
      if (value.find((v) => v === -1) || value.length === 0) {
        delete this.filters[key];
      } else {
        this.filters[key] = value;
      }
    }

    this.filterData(isMobile);
  }

  filterData(isMobile = false) {
    if(isMobile){
      this.mobileAllData = [...this.dataSource.value];
      Object.keys(this.filters).map((filterName) => {
        const filterValue = this.filters[filterName];
        const type = moment.isDate(filterValue) || moment.isMoment(filterValue) ? 'date' : typeof filterValue;
        switch (type) {
          case "string":
            this.mobileAllData = this.mobileAllData.filter((m) => m[filterName]?.toLowerCase().includes(filterValue.toLowerCase()));
            break;
          case "number":
            this.mobileAllData = this.mobileAllData.filter((m) => m[filterName] === filterValue);
            break;
          case 'date':
            this.mobileAllData = this.mobileAllData.filter(m => moment(filterValue).startOf('day').isSame(moment(m[filterName]).startOf('day')))
          default:
            break;
        }
      });
      this.sliceDataSource(isMobile);
    }
    else{
      this.allData = [...this.dataSource.value];
      Object.keys(this.filters).map((filterName) => {
        const filterValue = this.filters[filterName];
        const type = moment.isDate(filterValue) || moment.isMoment(filterValue) ? 'date' : typeof filterValue;
        switch (type) {
          case "string":
            this.allData = this.allData.filter((m) => m[filterName]?.toLowerCase().includes(filterValue.toLowerCase()));
            break;
          case "number":
            this.allData = this.allData.filter((m) => m[filterName] === filterValue);
            break;
          case 'date':
            this.allData = this.allData.filter(m => moment(filterValue).startOf('day').isSame(moment(m[filterName]).startOf('day')))
          default:
            break;
        }
      });
      this.sliceDataSource();
    }
  }

  sliceDataSource(isMobile = false) {
    if(isMobile){
      if([...this.displayinfScrollDataSource.value].length != this.mobileAllData.length)
        this.displayinfScrollDataSource.next(this.mobileAllData);
    }
    else{
      this.displayDataSource.next(
        this.allData.slice(
          this.pageIndex * this.pageSize,
          this.pageIndex * this.pageSize + this.pageSize
        )
      );
    }
  }

  resetTable = () => {
    this.filters = {}
    this.sortData(null)
    this.filterData()
  }
}
