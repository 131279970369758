
export interface User {

}

export enum UserTypes {
  Admin = 1,
  ClientAdmin = 2,
  ClientUser = 3,
  User = 4,
  Owner = 5,
  UnregisteredUser=6,
  AdminAdvisor =7
}

export enum UsernameStatus {
  Free = 1,
  Exists = 2,
  Pending = 3,
  Deleted = 4
}

export enum UserStatus {
  Active = 1,
  Inactive = 2
}