import { Injectable } from "@angular/core";
import { HttpInterceptor, HttpRequest, HttpHandler, HttpHeaders, HttpErrorResponse } from "@angular/common/http";
import { Observable, of } from "rxjs";
import { SessionService } from 'src/app/shared/service/session.service';
import { catchError, finalize, mergeMap, first } from "rxjs/operators";
import { Router } from "@angular/router";
import { AuthService } from "../../modules/bento-auth/auth.service";
import { LoadingService } from "./loading.service";
import { UserService } from "../../features/user/user.service";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  baseUrl:any;
  result:any=false;
  openUrls: string[] = ['open/ui/create/MYOBCompanyID','open/ui/create/MYobaccesstoken','open/ui/create/companyexists','open/ui/validate/Coupon','open/ui/create/BRaccesstoken','open/ui/user/getaccesstoken','open/ui/create/exists','open/ui/create/signup','open/ui/user/confirmationEmail', 'open/ui/user/verifytoken', 'open/ui/user/verifyemail','open/ui/user/verifyLink','open/ui/user/setPassword','open/ui/user/markActive','open/ui/user/addCookies', 'open/ui/projectstatus/List', 'open/ui/signup/BRPriceList', 'open/ui/signup/FYIPriceList', 'open/ui/signup/KarbonPriceList'];
  constructor(
    private session: SessionService,
    private router: Router,
    private authService: AuthService,
    private loadingService: LoadingService,
    private userService: UserService
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    debugger;
    this.loadingService.showLoader();
    if (request.url) {
      var pathArray = request.url.split('/api/');
      let endpoint = pathArray[1];
      this.result = this.openUrls.includes(endpoint);
    }
    if (this.result) {
      var newRequest = request.clone({
        withCredentials: true
      });
      return next.handle(newRequest).pipe(
        catchError((error: HttpErrorResponse) => {
          debugger;
          if(error.status===401)
          {
            console.log('Invalid client data');
            this.router.navigate(['/error/error-7']);
          }
          if (typeof error === 'string' && error === 'not authenticated') {
            this.authService.logout();
            this.router.navigate(['/login']);
            return of();
          }
          if ((error.error && error.error.message === 'MAINTENANCE_MODE') || (error.error && error.error.message=='Invalid Refresh Token') || (error.error && error.error.message=='Missing required parameter REFRESH_TOKEN')) {
            this.authService.logout();
            this.router.navigate(['/login'])
            return of();
          }
          throw new Error(error.message);
        }),
        finalize(() => this.loadingService.hideLoader())
        )


     // return next.handle(newRequest);
    }
    else if(this.session.getIdToken() && this.session.getRefreshToken()){ 
      debugger;
      const expiryDate=this.session.getTokenExpiry();
      if(expiryDate && new Date() >= new Date(expiryDate))
      {
        return this.userService.getAccessToken(this.session.getRefreshToken())
          .pipe(
            first(),
            mergeMap(token => {            
              debugger;
              console.log('idtoken');
              console.log(token);
              this.session.saveRefreshToken(token.refreshToken);
              this.session.saveIdToken(token.idToken);
              this.isTokenExpired(3600);
              return this.doRequest(request, next);
            })
          );

      }

      else{
        return this.doRequest(request, next);
      }
    }
    else if (this.session.getIdToken()) {
      const expiryDate = this.session.getTokenExpiry();
      if (expiryDate && new Date() >= new Date(expiryDate)) {
        return this.userService.getAccessToken(this.session.getRefreshToken())
          .pipe(
            first(),
            mergeMap(token => {
              debugger;
              console.log('idtoken');
              console.log(token);
              this.session.saveRefreshToken(token.refreshToken);
              this.session.saveIdToken(token.idToken);
              this.isTokenExpired(3600);

              return this.doRequest(request, next);
            })
          );
      }
      else {
        return this.doRequest(request, next);
      }
    }
    else {
      this.authService.logout();
      this.loadingService.hideLoader();
      this.router.navigate(['/login']);
    }  // new logic end 
       
  }

  doRequest(request: HttpRequest<any>, next: HttpHandler){
    let session: string = this.session.getSession() ? JSON.stringify(this.session.getSession()) : '';
    var newRequest = request.clone({
      headers: new HttpHeaders({
        "session": session,
        'Authorization': this.session.getIdToken(),
      }),withCredentials:true
    })

    return next.handle(newRequest).pipe(
      catchError((error: HttpErrorResponse) => {
        debugger;
        if(error.status===401)
        {
          console.log('Invalid client data');
          this.router.navigate(['/error/error-7']);
        }
        if(error.status===403)
        {
          console.log('Invalid client data');
          this.router.navigate(['/error/error-7']);
        }
        if (typeof error === 'string' && error === 'not authenticated') {
          this.authService.logout();
          this.router.navigate(['/login']);
          return of();
        }
        if ((error.error && error.error.message === 'MAINTENANCE_MODE') || (error.error && error.error.message=='Invalid Refresh Token') || (error.error && error.error.message=='Missing required parameter REFRESH_TOKEN')) {
          this.authService.logout();
          this.router.navigate(['/login'])
          return of();
        }
        throw new Error(error.message);
      }),
      finalize(() => this.loadingService.hideLoader())
    )
  }

  refreshAccessToken= ()=>
  {
    debugger;
    const expiryDate=this.session.getTokenExpiry();
    if(expiryDate && new Date() >= new Date(expiryDate))
    {
      this.userService.getAccessToken(this.session.getRefreshToken())
      .subscribe(async token => {
        debugger;
        console.log('idtoken');
        console.log(token);
        this.session.saveRefreshToken(token.refreshToken);
        this.session.saveIdToken(token.idToken);
        this.isTokenExpired(3600);
      });
    }
  }
  
  isTokenExpired(seconds) {
    let minutes = Math.floor(seconds / 60);
    var currentTimeDate = new Date();
    currentTimeDate.setMinutes(currentTimeDate.getMinutes() + minutes - 10);
    this.session.saveTokenExpiry(currentTimeDate);
    console.log('expirein');
    console.log(currentTimeDate);
  }
}