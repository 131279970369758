import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'b-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss']
})
export class ConfirmationDialogComponent implements OnInit {
  messages: string[]
  title: string
  cancelButton: boolean

  constructor(
    public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public config: any
  ) { }

  ngOnInit(): void {
    this.messages = this.config.messages
    this.title = this.config.title
    this.cancelButton = this.config.cancelButton != null ? this.config.cancelButton : true
  }

  closeModal = (ok: boolean) => {
    this.dialogRef.close(ok)
  }

}
