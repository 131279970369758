import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { SessionService } from '../../service/session.service';

@Component({
  selector: 'b-first-time-login',
  templateUrl: './first-time-login.component.html',
  styleUrls: ['./first-time-login.component.scss']
})
export class FirstTimeLoginComponent implements OnInit {
  platfomType:any;
  constructor(
    public dialogRef: MatDialogRef<FirstTimeLoginComponent>,
    private session: SessionService,
  ) { }

  ngOnInit(): void {
    let session:any = this.session.getSession()
    this.platfomType=session.platformType;
  }

  closeModal = () => {
    let session:any = this.session.getSession()
    session.firstLogin = false
    this.session.setSession(session)
    this.dialogRef.close()
  }
}
